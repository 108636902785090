import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const TigerTradePrefix = 'tt_';

enum PagedIds {
  'home-page',
  'contacts',
  'terminal',
  'terminal_overview',
  'terminal_how-to-setup',
  'terminal_prices',
  'terminal_login',
  'terminal_download',
}

type PageId = keyof typeof PagedIds;

enum MetaTags {
  'title',
  'description',
  'og_title',
  'og_description',
}

type MetaTag = keyof typeof MetaTags;

/**
 * Returns translation for the specific meta tag
 * Tries to find translation by key «TigerTradePrefix + MetaTag + "_" + transformed pathname»
 * If found returns it, otherwise returns default (home page) translation, otherwise empty string
 * */
export const useTranslationByMetaTag = (metaTag: MetaTag) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const pageId = getPageId(pathname);
  const defaultTranslationKey = `${TigerTradePrefix}${metaTag}_home-page`;
  const defaultTranslation = t(defaultTranslationKey);
  const fallbackTranslation =
    defaultTranslation && defaultTranslation !== defaultTranslationKey
      ? defaultTranslation
      : 'tiger.com';

  const translationKey = `${TigerTradePrefix}${metaTag}_${pageId}`;
  const translation = t(translationKey);

  if (!pageId || !translationKey || !translation || translation === translationKey) {
    return fallbackTranslation;
  }

  return translation;
};

const slash = '/';

const getPageId = (pathname: string): PageId | null => {
  if (typeof pathname !== 'string') {
    return null;
  }

  if (pathname === slash) {
    return 'home-page';
  }

  const noFirstSlashString = pathname.startsWith(slash) ? pathname.replace(slash, '') : pathname;
  const pageId = noFirstSlashString.replace(slash, '_');

  if (checkIsPageId(pageId)) {
    return pageId;
  }

  return null;
};

const checkIsPageId = (value: string): value is PageId => value in PagedIds;
