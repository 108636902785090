import moment from 'moment';
import { decode } from 'js-base64';
import { TCurrencySymbol } from '@/core/infra';
import { useLocalStorage } from '@/src/hooks/useLocalStorage';

export const wrap =
  (fn, ...args) =>
  () =>
    fn(...args);

export const wrapEvent =
  (fn, ...args) =>
  (event) => {
    event.stopPropagation();
    event.preventDefault();
    fn(...args);
  };

export const wrapOnChange =
  (fn) =>
  ({ target: { value } }) =>
    fn(value);

export const genID = function () {
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

const parseCookie = () => {
  const cookieArr = document.cookie.split(';');
  return cookieArr.reduce((acc, item) => {
    const [key, value] = item.trim().split('=');
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const LocalStorageService = {
  set: (key, value) => {
    const date = moment().add(30, 'days');
    const localStorage = useLocalStorage();
    localStorage.set(key, value);
    document.cookie = `${key}=${value};expires=${date};path=/`;
  },
  get: (key) => {
    const cookie = parseCookie();
    return cookie[key];
  },
  remove: (key) => {
    const cookie = parseCookie();
    document.cookie = `${key}=${cookie[key]};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    localStorage.removeItem(key);
  },
};

export const parseJwt = () => {
  const token = LocalStorageService.get('accessToken');
  if (token) {
    try {
      return JSON.parse(decode(token.split('.')[1]));
    } catch (e) {
      console.error(e);
    }
  }
  return '';
};

export const cutUserName = (email) => {
  const index = email.indexOf('@');
  const name = email.slice(0, index);
  return name;
};

export const parseStringToNumber = (string) => {
  const int = parseInt(string, 10);
  if (int) {
    return int;
  }
  const arr = string.split('');
  const value = arr.reduce((acc, item) => {
    if (!Number(item)) {
      return acc;
    }
    return `${acc}${item}`;
  }, '');
  return Number(value);
};

export const getDiscount = (amount, discount) => {
  const price = amount + discount;
  const formattedPrice = new Intl.NumberFormat('ru-RU').format(amount + discount);
  const save = new Intl.NumberFormat('ru-RU').format(Math.floor((discount / price) * 100));
  return {
    price,
    formattedPrice,
    save,
  };
};

export const sortedArr = (arr) => {
  const sortData = arr.sort((a, b) => {
    if (parseStringToNumber(a.name) < parseStringToNumber(b.name)) {
      return -1;
    }
    if (parseStringToNumber(a.name) > parseStringToNumber(b.name)) {
      return 1;
    }
    return 0;
  });

  return sortData;
};

export const isMobileDevice = () => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return regex.test(window.navigator.userAgent);
};

export const declension = (n, declensionsSource) => {
  let declensions = declensionsSource;
  if (typeof declensionsSource === 'string') {
    declensions = declensionsSource.split('|');
  }

  if (Number.isNaN(n) || declensions.length === 1) {
    return declensionsSource;
  }

  let declensionIndex = 0;
  const $mod10 = n ? n % 10 : 1;
  const $mod100 = n ? n % 100 : 1;

  if (n === 0) {
    declensionIndex = 0;
  } else if (Number.isInteger(n) && $mod10 === 1 && $mod100 !== 11) {
    declensionIndex = 1;
  } else if ($mod10 > 1 && $mod10 < 5 && ($mod100 < 12 || $mod100 > 14)) {
    declensionIndex = 2;
  } else if ($mod10 === 0 || ($mod10 > 4 && $mod10 < 10) || ($mod100 > 10 && $mod100 < 15)) {
    declensionIndex = 3;
  } else {
    declensionIndex = 4;
  }

  return declensions[declensionIndex] ? declensions[declensionIndex] : '';
};

export const cutPageName = (path) => {
  const isQueryParam = path.split('?');
  const page = isQueryParam[0].slice(1);
  const name = path.replace(/#(.+)|\?|^\//gi, '');
  if (!name) {
    return ['index'];
  }
  if (isQueryParam.length === 1) {
    const param = name.split('/')[1];
    if (param) return param;
    else return name;
  }
  return page;
};

export const isNeedToGoPreviousPage = (
  previousPage: string,
  pagesForRedirect: string[]
): boolean => {
  return pagesForRedirect.some((redirectPage) => previousPage.split('/').includes(redirectPage));
};

export const formatPrice = (value: number, currency: TCurrencySymbol) => {
  let amount = new Intl.NumberFormat(currency === '$' ? 'en' : 'ru-RU').format(value);
  if (currency === '$') {
    amount = currency + amount;
  } else {
    amount += ' ' + currency;
  }
  return amount;
};
