import style from './style.module.scss';
import clsx from 'clsx';
import Facebook from '../../../public/images/social/facebook.svg';
import Twitter from '../../../public/images/social/twitter.svg';
import Telegram from '../../../public/images/social/telegram.svg';
import Discord from '../../../public/images/social/discord.svg';
import Youtube from '../../../public/images/social/youtube.svg';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { COPYTRADING_LINKS, TERMINAL_MAC_OS_LINKS, BROKER_LINKS } from '@/core/constants';

const Footer = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className={style.main}>
      <div className={style.landingFooter}>
        <div className={style.mainContainer}>
          <div className={style.logo} />
          <div className={style.footMenu}>
            <div className={style.follow}>
              {t('footer_section3')}
              <div className={style.social}>
                {/*
                <a
                  target="_blank"
                  href="https://www.facebook.com/Tiger.Trade.Official"
                  className={style.icon}
                >
                  <Facebook />
                </a>
                */}
                <a
                  target="_blank"
                  href={t('social-href_twitter')}
                  className={clsx(style.icon, style.twitter)}
                >
                  <Twitter />
                </a>
                <a target="_blank" href={t('social-href_telegram')} className={style.icon}>
                  <Telegram />
                </a>
                <a target="_blank" href={t('social-href_youtube')} className={style.icon}>
                  <Youtube />
                </a>
                <a target="_blank" href={t('social-href_discord')} className={style.icon}>
                  <Discord />
                </a>
              </div>
            </div>
            <div className={style.col}>
              Tiger.Com
              {/*<a href='#'>About company</a>*/}
              <Link href="/">{t('footer_home_button2')}</Link>
              <Link href="/contacts">{t('footer_contacts_button1')}</Link>
            </div>
            <div className={style.col}>
              {t('footer_section2')}
              <Link href="/terminal">{t('footer_wnd_tp_button')}</Link>
              <Link href={TERMINAL_MAC_OS_LINKS[locale]}>{t('footer_mac_tp_button')}</Link>
              <Link href={BROKER_LINKS[locale]}>{t('header_products_broker_redirect_button')}</Link>
              {/* <Link href={COPYTRADING_LINKS[locale]}>{t('footer_copytraing_button')}</Link> */}
            </div>
          </div>
          <div className={style.footText}>
            <div className={style.prod}>
              {t('footer_law_section1')}
              <br />
              {t('footer_law_section2')}
            </div>
            <div className={style.rights}>
              {t('footer_law_section3')}
              <br />
              {t('footer_law_section4')}
            </div>
            <div className={style.links}>
              <Link href="/policy">
                <a>{t('footer_law_privacy_button')}</a>
              </Link>
              <Link href="/terminal/terms-of-service">
                <a>{t('footer_law_terms_button')}</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
