export const useLocalStorage = () => {
  function get(key) {
    if (typeof window !== 'undefined') {
      const localStorageItem = localStorage.getItem(key);
      return localStorageItem && JSON.parse(localStorageItem);
    }

    return {};
  }

  function set(key, data) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  function remove(key) {
    localStorage.removeItem(key);
  }

  return { get, set, remove };
};
