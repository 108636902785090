import { LicensesType } from '@/core/infra';

export const LICENSE_PRICE_CONFIG = {
  TigerX1: {
    type: LicensesType.STOCK_MARKET,
    months: 1,
  },
  TigerX3: {
    type: LicensesType.STOCK_MARKET,
    months: 3,
  },
  TigerX6: {
    type: LicensesType.STOCK_MARKET,
    months: 6,
    disabled: true,
  },
  TigerX12: {
    type: LicensesType.STOCK_MARKET,
    months: 12,
  },
  TigerXLT: {
    type: LicensesType.STOCK_MARKET,
  },
  PlayerX1: {
    type: LicensesType.PLAYER,
    months: 1,
  },
};
