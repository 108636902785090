import Image from 'next/image';

const mapImageForViewer = ({ images, lng, imageViewHandler }) => {
  const imageClickHandler = (index) => () => {
    imageViewHandler(index);
  };

  return images.reduce(
    (acc, image, index, alt = '') => {
      const imageFormatedData = {
        preview: {
          ...image.preview,
          src: image.preview.src.replace('[lng]', lng),
        },
        fullview: {
          ...image.fullview,
          src: image.fullview.src.replace('[lng]', lng),
        },
      };
      return {
        imagesData: [...acc.imagesData, imageFormatedData],
        imagesComponents: [
          ...acc.imagesComponents,
          <figure key={index} className="image wid-medium">
            <Image
              alt={alt}
              className="viewer-trigger"
              onClick={imageClickHandler(index)}
              {...imageFormatedData.preview}
            />
          </figure>,
        ],
      };
    },
    {
      imagesData: [],
      imagesComponents: [],
    }
  );
};

export { mapImageForViewer };
