export enum LicensesType {
  CRYPTO = 'CRYPTO',
  STOCK_MARKET = 'STOCK_MARKET',
  PLAYER = 'PLAYER',
}

export const LicenseName = {
  CRYPTO: 'Crypto',
  STOCK_MARKET: 'Full',
  PLAYER: 'Player',
} as const;

type LicenseStatus = 'ACTIVE' | 'EXPIRED' | 'INACTIVE';

type CommercialType = 'FREE' | 'PAID' | 'TRIAL';

export type TPersonalLicense = {
  licenseId: number;
  licenseType: LicensesType;
  licenseStatus: LicenseStatus;
  commerceType: CommercialType;
  platform: string;
  active: boolean;
  free: boolean;
  login: string;
  password: string;
  currency: string;
  days: number;
  purchaseDate?: string;
  expirationDate?: string;
  deviceId?: string;
  productId?: number;
};

export type TPersonalLicensesDTO = TPersonalLicense[];
