import { useEffect } from 'react';
import { throttle } from 'lodash';

export function useScroll(callback: (px: number) => void) {
  useEffect(() => {
    if (typeof window === undefined) return;

    const handler = throttle(() => {
      callback(window.scrollY);
    }, 100);

    window.addEventListener('scroll', handler);
  }, []);
}
