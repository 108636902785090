import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import {
  COPYTRADING_LINKS,
  SUPPORT_LINKS,
  TERMINAL_MAC_OS_LINKS,
  SUPPORT_BROKER_LINKS,
} from '@/core/constants';
import {
  BROKER_LINKS,
  LOGIN_WINDOWS_LINKS,
  LOGIN_MACOS_LINKS,
  LOGIN_BROKER_LINKS,
} from '@/core/constants/NavigationLinks';
import { LANGUAGES } from '@/constant';
import { LocalStorageService } from '@/helper';
import CopytradingIcon from '../../../public/images/menu/copytrading.svg';
import MacOSIcon from '../../../public/images/menu/macos.svg';
import BrokerIcon from '../../../public/images/menu/broker.svg';
import WindowsIcon from '../../../public/images/menu/windows.svg';
import Facebook from '../../../public/images/social/facebook.svg';
import Reddit from '../../../public/images/social/reddit.svg';
import Youtube from '../../../public/images/social/youtube.svg';
import { useOutsideAlerter } from '../../../src/hooks/useOutsideClick';
import { useScroll } from '../../../src/hooks/useScroll';
import style from './style.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const { locale, asPath } = useRouter();

  const NAV = [
    {
      id: 1,
      dropId: 1,
      name: 'header_home_button',
      href: '/',
      icon: '',
      active: true,
    },
    {
      id: 2,
      dropId: 2,
      name: 'header_products_dropdown_button',
      href: '',
      icon: (
        <svg className="arrow" width={13} height={8}>
          <use xlinkHref="/img/menu/arrow.svg#arrow" />
        </svg>
      ),
      links: [
        {
          id: 1,
          href: '/terminal',
          name: 'header_in_products_windows_terminal_redirect_button',
          subName: 'header_in_products_windows_subname_redirect_button',
          menuIcon: <WindowsIcon />,
        },
        {
          id: 2,
          href: TERMINAL_MAC_OS_LINKS[locale],
          name: 'header_products_mac_terminal_redirect_button',
          subName: 'header_in_products_macos_subname_redirect_button',
          menuIcon: <MacOSIcon />,
        },
        {
          id: 3,
          href: BROKER_LINKS[locale],
          name: 'header_products_broker_redirect_button',
          subName: 'header_in_products_broker_subname_redirect_button',
          menuIcon: <BrokerIcon />,
        },
        /*
        {
          id: 4,
          href: COPYTRADING_LINKS[locale],
          name: 'header_in_products_copytrading_redirect_button',
          subName: 'header_in_products_copy_subname_redirect_button',
          menuIcon: <CopytradingIcon />,
        },
        */
      ],
    },
    {
      id: 3,
      dropId: 3,
      name: 'header_help_dropdown_button',
      href: '',
      icon: (
        <svg className="arrow" width={13} height={8}>
          <use xlinkHref="/img/menu/arrow.svg#arrow" />
        </svg>
      ),
      links: [
        /*{
          id: 1,
          href:
            locale !== 'ru'
              ? 'https://copy.tiger.trade/index-en.html#support'
              : 'https://copy.tiger.trade/index-ru.html#support',
          name: 'header_in_help_ct_hc_button',
        },*/
        {
          id: 2,
          href: SUPPORT_LINKS[locale],
          name: 'header_in_help_windows_tp_hc_button',
        },
        {
          id: 3,
          href: SUPPORT_BROKER_LINKS[locale],
          name: 'header_in_help_broker_tp_hc_button',
        },
      ],
    },
    // {
    //   id: 4,
    //   dropId: 4,
    //   name: 'header_career_button',
    //   href: '',
    //   icon: (
    //     <svg width={15} height={14}>
    //       <use xlinkHref="/img/menu/link.svg#link" />
    //     </svg>
    //   ),
    // },
    { id: 5, dropId: 5, name: 'header_contacts_button', href: '/contacts', icon: '' },
  ];

  const LOGIN = [
    {
      id: 1,
      href: LOGIN_WINDOWS_LINKS[locale],
      name: 'header_in_products_windows_terminal_redirect_button',
      subName: 'header_in_products_windows_subname_redirect_button',
      menuIcon: <WindowsIcon />,
    },
    {
      id: 2,
      href: LOGIN_MACOS_LINKS[locale],
      name: 'header_products_mac_terminal_redirect_button',
      subName: 'header_in_products_macos_subname_redirect_button',
      menuIcon: <MacOSIcon />,
    },
    {
      id: 3,
      href: LOGIN_BROKER_LINKS[locale],
      name: 'header_products_broker_redirect_button',
      subName: 'header_in_products_broker_subname_redirect_button',
      menuIcon: <BrokerIcon />,
    },
  ];

  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [activeMenuId, setActiveMenuId] = useState(1);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMobileMenuOpen, setIsSubMobileMenuOpen] = useState(false);
  const [isActiveHover, setIsActiveHover] = useState(false);
  const [activeHoverId, setActiveHoverId] = useState(0);
  const [useBg, setBg] = useState(false);
  const router = useRouter();

  const menuRef = useRef(null);
  const menuMobileRef = useRef(null);
  const menuLangRef = useRef(null);
  const menuLoginRef = useRef(null);

  useOutsideAlerter(menuRef, () => setIsSubMenuOpen(false), 200);
  useOutsideAlerter(menuMobileRef, () => setIsSubMobileMenuOpen(false), 200);
  useOutsideAlerter(menuLangRef, () => setIsLangOpen(false));
  useOutsideAlerter(menuLoginRef, () => setIsLoginOpen(false));

  useScroll((value) => setBg(value > 0));

  const openDropdown = (id: number) => {
    setActiveMenuId(id);
    setIsSubMenuOpen(activeMenuId === id ? !isSubMenuOpen : true);
    setIsSubMobileMenuOpen(activeMenuId === id ? !isSubMobileMenuOpen : true);
  };

  const activeHoverLinkHandler = (id: number) => {
    setActiveHoverId(id);
    setIsActiveHover(true);
    if (activeHoverId === id) {
      setIsActiveHover(!activeHoverId);
    }
  };

  const notActiveHoverLinkHandler = (id: number) => {
    setActiveHoverId(id);
    setIsActiveHover(false);
  };

  return (
    <div className={clsx(style.outer, style.header, useBg && style.bg)}>
      <div className={style.mainContainer}>
        <div
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={clsx(style.header_toggle, isMenuOpen && style.activeToggle)}
        >
          <div className={style.line} />
          <div className={style.line} />
        </div>
        <Link href={'/'}>
          <div className={style.header_logo} />
        </Link>
        <div className={style.header_left}>
          <div className={style.header_left_nav} ref={menuRef}>
            {NAV.map((item) =>
              item.id !== 2 && item.id !== 3 ? (
                <Link key={item.id} href={item.href}>
                  <div
                    onClick={() => openDropdown(item.dropId)}
                    className={clsx(
                      style.header_left_nav_link,
                      item.href === router.asPath && style.active,
                      activeMenuId === item.dropId && isSubMenuOpen && style.openActive
                    )}
                  >
                    {t(item.name)} {item.icon && item.icon}
                    {isSubMenuOpen && (
                      <div className={style.list}>
                        {item.links?.map((link) => (
                          <Link key={link.id} href={link.href}>
                            <span
                              onMouseEnter={() => activeHoverLinkHandler(link.id)}
                              onMouseLeave={() => notActiveHoverLinkHandler(0)}
                              className={clsx(
                                style.listItem,
                                isActiveHover && activeHoverId === link.id && style.listItemActive,
                                isActiveHover &&
                                  activeHoverId !== link.id &&
                                  style.listItemNotActive
                              )}
                            >
                              {link.menuIcon}
                              <div className={style.textWrapper}>
                                <div className={style.itemTitle}>{t(link.name)}</div>
                                {link.subName && (
                                  <div className={style.itemSubTitle}>{t(link.subName)}</div>
                                )}
                              </div>
                            </span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </Link>
              ) : (
                <div key={item.id}>
                  <div
                    onClick={() => openDropdown(item.dropId)}
                    className={clsx(
                      style.header_left_nav_link,
                      item.href === router.asPath && style.active,
                      activeMenuId === item.dropId && isSubMenuOpen && style.openActive
                    )}
                  >
                    {t(item.name)} {item.icon && item.icon}
                    {isSubMenuOpen && (
                      <div className={style.list}>
                        {item.links?.map((link) => (
                          <Link key={link.id} href={link.href}>
                            <span
                              onMouseEnter={() => activeHoverLinkHandler(link.id)}
                              onMouseLeave={() => notActiveHoverLinkHandler(0)}
                              className={clsx(
                                style.listItem,
                                isActiveHover && activeHoverId === link.id && style.listItemActive,
                                isActiveHover &&
                                  activeHoverId !== link.id &&
                                  style.listItemNotActive
                              )}
                            >
                              {link.menuIcon && link.menuIcon}
                              <div className={style.textWrapper}>
                                <div className={style.itemTitle}>{t(link.name)}</div>
                                {link.subName && (
                                  <div className={style.itemSubTitle}>{t(link.subName)}</div>
                                )}
                              </div>
                            </span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className={style.header_left_lang}>
            <div
              onClick={() => setTimeout(() => setIsLangOpen(!isLangOpen), 0)}
              className={clsx(
                style.header_left_lang_toggle,
                isLangOpen && style.header_left_lang_toggleOpen
              )}
            >
              <span>{locale}</span>
            </div>
            <div
              ref={menuLangRef}
              className={clsx(
                style.header_left_lang_sub,
                isLangOpen && style.header_left_lang_subOpen
              )}
            >
              {LANGUAGES.map((item) => (
                <Link key={item.value} href={asPath} locale={item.value} passHref>
                  <span
                    className={clsx(
                      style.toggleItem,
                      locale === item.value && style.toggleItemActive
                    )}
                    key={item.name}
                    onClick={() => LocalStorageService.set('language', item.value)}
                  >
                    {t(item.name)}
                  </span>
                </Link>
              ))}
            </div>
          </div>
          <div className={style.header_left_login}>
            <button
              onClick={() => setTimeout(() => setIsLoginOpen(!isLoginOpen), 0)}
              className={clsx(style.header_left_login_toggle, isLoginOpen && style.active)}
            >
              <span>{t('login')}</span>
              <svg className={style.header_left_login_chevron} width={13} height={8}>
                <use xlinkHref="/img/menu/arrow.svg#arrow" />
              </svg>
            </button>
            <div
              ref={menuLoginRef}
              className={clsx(style.header_left_login_dropdown, { [style.active]: isLoginOpen })}
            >
              {LOGIN.map((link) => (
                <Link key={link.id} href={link.href}>
                  <span className={style.header_left_login_item}>
                    {link.menuIcon}
                    <div>
                      <div className={style.title}>{t(link.name)}</div>
                      {link.subName && <div className={style.subtitle}>{t(link.subName)}</div>}
                    </div>
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div className={style.leftMobile} ref={menuMobileRef}>
            <div className={style.top}>
              <div className={style.nav}>
                {NAV.map((item) => (
                  <Link key={item.id} href={item.href}>
                    <div
                      onClick={() => openDropdown(item.dropId)}
                      className={clsx(
                        style.link,
                        activeMenuId === item.dropId && isSubMobileMenuOpen && style.openActive
                      )}
                    >
                      {t(item.name)}
                      {activeMenuId === item.dropId && isSubMobileMenuOpen && item.links && (
                        <div className={style.list}>
                          {item.links?.map((link) => (
                            <Link key={link.id} href={link.href}>
                              <div className={style.listLink}>{t(link.name)}</div>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  </Link>
                ))}
              </div>
              <div className={style.lang}>
                <div className={clsx(style.sub)}>
                  {LANGUAGES.map((item) => (
                    <Link key={item.value} href={asPath} locale={item.value} passHref>
                      <span
                        className={clsx(
                          style.toggleItem,
                          locale === item.value && style.toggleItemActive
                        )}
                        key={item.name}
                        onClick={() => LocalStorageService.set('language', item.value)}
                      >
                        {t(item.name)}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className={style.follow}>
              Follow us on
              <div className={style.social}>
                <a className={style.icon}>
                  <Facebook />
                </a>
                <a className={style.icon}>
                  <Youtube />
                </a>
                <a className={style.icon}>
                  <Reddit />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
