import { MutableRefObject, useCallback, useEffect } from 'react';

export function useOutsideAlerter(
  ref: MutableRefObject<HTMLElement>,
  callback: () => void,
  delay = 0
) {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target;
      if (!(target instanceof Node)) {
        return;
      }

      if (!ref.current) {
        return;
      }

      if (ref.current.contains(target)) {
        return;
      }

      setTimeout(() => callback(), delay);
    },
    [callback, delay]
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);
}
